import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({children}) => {
  const [darkClass, setDarkClass] = useState('light');
  const [sidebarStatus, setSidebarStatus] = useState('sidebar-visible');

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      let lsTheme = localStorage.getItem('mk-theme');
      let lsSidebar = localStorage.getItem('mk-sidebar');
      setDarkClass(lsTheme ? lsTheme : 'light');
      let widthShowHide = window.innerWidth > 760 ? 'sidebar-visible' : 'sidebar-hidden';
      // setSidebarStatus(lsSidebar ? lsSidebar + ' immediate' : widthShowHide);
      setSidebarStatus(lsSidebar ? lsSidebar : widthShowHide);
    } else {
      setDarkClass('light');
      setSidebarStatus('sidebar-visible');
    }
  }, []);

  function changeMode(e) {
    e.persist();
    if (e.key === 'Enter' || e.key === undefined) {
      if (darkClass === 'dark') {
        setDarkClass('light');
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('mk-theme', 'light');
        }
      } else {
        setDarkClass('dark');
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('mk-theme', 'dark');
        }
      }
    }
  }

  function showHideSidebar(e) {
    e.persist();
    if (e.key === 'Enter' || e.key === undefined) {
      if (sidebarStatus === 'sidebar-visible' || sidebarStatus === 'sidebar-visible immediate') {
        setSidebarStatus('sidebar-hidden');
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('mk-sidebar', 'sidebar-hidden');
        }
      } else {
        setSidebarStatus('sidebar-visible');
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('mk-sidebar', 'sidebar-visible');
        }
      }
    }
  }

  function linkNav(e, isMobile) {
    e.persist();
    if (isMobile) {
      setSidebarStatus('sidebar-hidden');
    }
  }

  return (
    <AppContext.Provider value={{darkClass, changeMode, showHideSidebar, linkNav}}>
      <div data-theme={darkClass} className={`${sidebarStatus}`}>
        {children}
      </div>
    </AppContext.Provider>
  )
}
