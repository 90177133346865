/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import RootElement from "./src/context/root_element";

require("prismjs/themes/prism-tomorrow.css");

export const wrapRootElement = ({element}) => {
  return <RootElement>{element}</RootElement>
};

export const onRouteUpdate = () => {
  if (document.querySelector('.instagram-media')) {
    setTimeout(() => {
      if (
        typeof gatsbyLoadInstagram !== 'undefined' &&
        typeof window.gatsbyLoadInstagram === 'function'
      ) {
        window.gatsbyLoadInstagram();
      }
    }, 0);
  }
}